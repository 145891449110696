import React, { FC, useLayoutEffect, useRef } from 'react';
import { Link } from 'gatsby';
import classNames from 'classnames';
import { ProductsCategoriesProps } from './models';

import '../ProductsCategoriesOverride.scss';

const ProductsCategories: FC<ProductsCategoriesProps> = ({
  productsCategories: categories,
  currentCategory,
  colorExtraClass,
}) => {
  const activeScrollRef = useRef<HTMLInputElement>(null);
  const handleScrollPosition = () => {
    const scrollPosition = sessionStorage.getItem('scrollPosition');

    if (scrollPosition) {
      const handleScroll = new Promise((resolve) => {
        resolve(window.scrollTo(0, Number(scrollPosition)));
      });
      handleScroll.then(() => {
        sessionStorage.removeItem('scrollPosition');
      });
    }
  };

  const setScrollPosition = () => {
    sessionStorage.setItem('scrollPosition', window.pageYOffset.toString());
  };

  useLayoutEffect(() => {
    handleScrollPosition();
  });
  useLayoutEffect(() => {
    activeScrollRef?.current?.scrollIntoView({ inline: 'center' });
  }, [currentCategory]);

  return (
    <div
      className={`products-list__wrapper ${colorExtraClass || ''}`}
      data-testid="products-list-categories"
    >
      <div className="products-list__category">
        {categories
          .filter(({ hideInCategoriesList }) => !Number(hideInCategoriesList))
          .map(({ link, name, ariaLabel }) => (
            <div
              className="products-list__link-wrapper"
              key={name}
              ref={name === currentCategory ? activeScrollRef : null}
            >
              <Link
                className={classNames('products-list__link', {
                  'products-list__link--active': name === currentCategory,
                })}
                to={link}
                aria-label={ariaLabel}
                onClick={() => {
                  setScrollPosition();
                }}
              >
                {name}
              </Link>
            </div>
          ))}
      </div>
    </div>
  );
};

export default ProductsCategories;

import React, { FC, useState } from 'react';
import { Link } from 'gatsby';
import PlainImage from 'common/Image/PlainImage';
import Image from 'common/Image/Image';
import { ELEMENT_PRODUCT_IMAGE_WRAPPER } from 'utils/constants';
import '../RelatedProductsItemOverride.scss';
import DangerouslySetInnerHtml from 'common/DangerouslySetInnerHtml';
import ProductPriceSpider from 'components/Products/ProductPriceSpider';
import { RelatedProductsItems } from '../models';

const RelatedProductsItem: FC<RelatedProductsItems> = ({
  productDescriptionLong,
  productTitle,
  productTitleWithSize,
  productEAN,
  url,
  localProductImage,
  productImage,
  relatedProducts: { relatedProductsLearnMore, buyNow },
  variantProductCards,
  showTitleAsLink = true,
  colorExtraClass,
}) => {
  const [localImage, setLocalImage] = useState(localProductImage);
  const [plainImageClassName, setPlainImageClassName] = useState('');
  const [product, setProduct] = useState({
    url,
    image: productImage,
  });

  const updateCurrentVariant = (variant) => {
    setLocalImage(undefined);
    setProduct(variant);
    setPlainImageClassName('plain-image--variant');
  };

  const productDescription = productDescriptionLong
    ? `${productDescriptionLong?.split('.')[0]}.`
    : null;

  return (
    <div className="related-products__item" key={productTitle}>
      <div className="related-products__item--details">
        <div className="related-products__item--image" data-testid="related-products-image">
          {localImage ? <Image localImage={localImage} alt={productTitle} /> : null}
          {!localImage && product.image ? (
            <PlainImage
              lazyLoad
              src={product.image}
              alt={productTitle}
              className={plainImageClassName}
            />
          ) : null}
        </div>
        <h3 className={`related-products__item--title ${colorExtraClass || ''}`}>
          {showTitleAsLink ? (
            <Link aria-label={productTitle} to={`${product.url}${ELEMENT_PRODUCT_IMAGE_WRAPPER}`}>
              {productTitle || productTitleWithSize}
            </Link>
          ) : (
            productTitle || productTitleWithSize
          )}
        </h3>
        {variantProductCards ? (
          <div className="product-card__variants">
            {variantProductCards.map((variant) => (
              <div
                onClick={() => updateCurrentVariant(variant)}
                key={variant.size}
                className={`product-card__variants-size ${
                  variant.url === product.url ? 'product-card__variants-size--active' : ''
                }`}
                role="button"
                tabIndex={0}
              >
                {variant.size}
              </div>
            ))}
          </div>
        ) : null}
        {productDescription ? (
          <div className="related-products__item--description">
            <DangerouslySetInnerHtml html={productDescription} />
          </div>
        ) : null}
        <div className="related-products__item--buttons">
          {buyNow ? (
            <a
              href={buyNow.link[0].url}
              aria-label={buyNow.ariaLabel}
              rel="noopener noreferrer"
              target="_blank"
              className="related-products__external-link"
            >
              {buyNow.text}
            </a>
          ) : null}
          <Link
            aria-label={relatedProductsLearnMore}
            to={`${product.url}${ELEMENT_PRODUCT_IMAGE_WRAPPER}`}
            className={`related-products__internal-link ${colorExtraClass || ''}`}
          >
            {relatedProductsLearnMore}
          </Link>
          {productEAN ? <ProductPriceSpider {...{ productEAN, currentCategory: '' }} /> : null}
        </div>
      </div>
    </div>
  );
};

export default RelatedProductsItem;

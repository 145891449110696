import React, { FC, useState } from 'react';
import classNames from 'classnames';

import Button from '../Button';
import UmbracoImage from '../Image/UmbracoImage';

import { ProductDetailsButtonProps } from './models';

import './ProductDetailsButton.scss';

const ProductDetailsButton: FC<ProductDetailsButtonProps> = ({
  dropdownButton,
  shops,
  isModalVisible,
}) => {
  const [isShopVisible, setIsShopVisible] = useState(false);

  const handleShopPopup = () => {
    setIsShopVisible(!isShopVisible);
  };

  return (
    <>
      <Button
        {...dropdownButton}
        className={classNames({ active: isModalVisible })}
        onClick={handleShopPopup}
        data-testid="products-details-button"
      >
        {dropdownButton.text}
      </Button>
      {isShopVisible ? (
        <div className="product-shops">
          <div className="product-shops__body">
            {shops
              ? shops.map(({ arialabel, shopImage, url }) => (
                  <div className="product-shops__shop" key={url}>
                    <a aria-label={arialabel} href={url} target="_blank" rel="noreferrer noopener">
                      <UmbracoImage image={shopImage} alt={arialabel} />
                    </a>
                  </div>
                ))
              : null}
          </div>
        </div>
      ) : null}
    </>
  );
};

export default ProductDetailsButton;

import React, { FC } from 'react';
import { ProductEtailersProps } from './models';

import './ProductEtailers.scss';

const ProductEtailers: FC<ProductEtailersProps> = ({ list, colorExtraClass }) => (
  <ul className="etailer__list">
    {list.map(({ url, name, target, queryString }) => (
      <li className="etailer__item">
        <a
          href={url + ( queryString ? queryString : '' )}
          className={`etailer__link ${colorExtraClass || ''}`}
          target={target}
          rel="noopener noreferrer"
        >
          {`Compra en ${name}`}
        </a>
      </li>
    ))}
  </ul>
);

export default ProductEtailers;

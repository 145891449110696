import React, { FC } from 'react';
import { RelatedProductsProps } from './models';
import RelatedProductsItem from './RelatedProductsItem/index';

import 'components/RelatedProducts/RelatedProducts.scss';

const RelatedProducts: FC<RelatedProductsProps> = ({
  relatedProductsNodes,
  relatedProductsTitle,
  colorExtraClass,
}) => {
  return (
    <div className="related-products" data-testid="related-products">
      <h2 className="related-products__title">{relatedProductsTitle}</h2>
      <div className="related-products__items">
        {relatedProductsNodes.map((relatedProductsNode) => (
          <RelatedProductsItem
            key={relatedProductsNode.productTitle}
            {...relatedProductsNode}
            {...{ colorExtraClass }}
          />
        ))}
      </div>
    </div>
  );
};

export default RelatedProducts;
